
import { NULLABLE } from '@/components/shared/utils';
import { getTextGenServiceEndpoint } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import TextGenAPIService, { SummaryStatus, SummaryTypeCode, VisitTextDomain } from '@iodine/tgs-api-client-node';
import { Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'GenAiTextArea',
    props: {
        value: { type: String, required: false },
        typeCode: { type: String as PropType<SummaryTypeCode>, required: true },
        placeholderText: { type: String, required: true },
        generateHelpText: { type: String, required: true },
        generateButtonText: { type: String, required: true },
    },
    data: () => ({
        internalValue: '',
        loading: false,
        visitStore: useVisitStore(),
        fetchTimeout: undefined as NodeJS.Timeout | undefined,
        textGenAPIService: undefined as TextGenAPIService | undefined,
        showDisclaimer: false,
    }),
    computed: {
        showHelp(): boolean {
            return !this.hasText && !this.loading;
        },
        hasText(): boolean {
            return this.internalValue.length > 0;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue: NULLABLE<string>) {
                this.internalValue = newValue ?? '';
            },
        },
        internalValue: {
            immediate: true,
            handler(newValue) {
                if (newValue === '') {
                    this.showDisclaimer = false;
                }
            },
        },
    },
    destroyed() {
        clearTimeout(this.fetchTimeout);
    },
    methods: {
        async insertText(): Promise<void> {
            try {
                this.loading = true;
                const service = await this.getTextGenAPIService();
                await this.generateSummary(service);

                const text = await this.fetchSummary(service);
                if (this.internalValue) this.internalValue += '\n\n';
                this.internalValue += text;
                this.showDisclaimer = true;
                this.focus();
            } catch (e) {
                console.error(e);
                this.$toast.error('Could not insert clinical summary, please try again', { timeout: -1 });
            } finally {
                this.loading = false;
            }
        },
        saveText(): void {
            if (this.value !== this.internalValue) {
                this.$emit('changed', this.internalValue);
            }
        },
        focus(): void {
            this.$nextTick(() => {
                const textarea = this.$refs.textarea as HTMLElement;
                if (textarea) textarea.focus();
            });
        },
        async getTextGenAPIService(): Promise<TextGenAPIService> {
            // Lazy-load the client, as the user may not want to use the feature and so that they can retry their request
            // if the config wasn't available (e.g. if the visit wasn't loaded into the VisitStore yet).
            if (this.textGenAPIService) return this.textGenAPIService;

            const endpoint = await this.$apollo
                .query<Query>({ query: getTextGenServiceEndpoint, fetchPolicy: 'cache-first' })
                .then((response) => response.data.config.textGenService?.endpoint);
            const token = await this.$auth.tokenManager.getTokens().then((tokens) => tokens.accessToken?.accessToken);
            const { id: visitId, division: divisionId } = this.visitStore.visit ?? {};

            if (endpoint && token && visitId && divisionId) {
                this.textGenAPIService = new TextGenAPIService();
                this.textGenAPIService.setAxiosConfig(endpoint, token, +visitId, divisionId, VisitTextDomain.UM);
                return this.textGenAPIService;
            } else {
                throw new Error('Invalid config for TGS API client');
            }
        },
        async generateSummary(service: TextGenAPIService): Promise<void> {
            const result = await service.generateSummary(this.typeCode);
            if (result.err) {
                throw new Error(`Error generating ${this.typeCode} summary`);
            }
        },
        async fetchSummary(service: TextGenAPIService): Promise<string> {
            let status: SummaryStatus | undefined;
            do {
                const result = await service.fetchSummary(this.typeCode);
                const data = result.data;
                status = data?.status;

                if (result.err || status === SummaryStatus.FAILED) {
                    throw new Error(`Error fetching ${this.typeCode} summary`);
                } else if (status === SummaryStatus.PENDING) {
                    clearTimeout(this.fetchTimeout);
                    await new Promise((f) => {
                        this.fetchTimeout = setTimeout(f, 2000);
                    });
                } else if (data && status === SummaryStatus.COMPLETE) {
                    return this.removeCitations(data.content);
                }
            } while (status === SummaryStatus.PENDING);
            throw new Error(`Unexpected status while fetching ${this.typeCode} summary`);
        },
        removeCitations(html: string): string {
            // This component does not currently support citations (represented as HTML span tags) so remove them from the text.
            return html.replace(/<\/?span[^>]*>/gi, '');
        },
    },
});
