
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import Loadable from '@/components/shared/Loadable.vue';
import PDFBlobDisplay from '@/components/shared/PDFBlobDisplay.vue';
import SaveClinicalDialog from '@/components/visit/clinical-document/SaveClinicalDialog.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { ClinicalDocumentType, DrawerType } from '@/models';
import { ClinicalDocumentTypeDisplay } from '@/models/clinical-document/ClinicalDocument.model';
import { saveClinicalDocument, sendClinicalDocument } from '@/shared/mutations';
import { getClinicalDocumentDraftWithPDF } from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { FaxNumber, Maybe, Mutation, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';
import DeleteDialog from './DeleteDialog.vue';

export default Vue.extend({
    name: 'ClinicalDocumentConfirmationView',
    components: {
        FeatureToggle,
        DrawerHeader,
        PDFBlobDisplay,
        DeleteDialog,
        Loadable,
        SaveClinicalDialog,
    },
    props: {
        faxNumber: { type: Object as PropType<FaxNumber>, required: true },
        payerName: { type: String, required: true },
        visitId: { type: String, required: true },
        clinicalDocumentId: { type: String, required: true },
        documentType: { type: String as PropType<ClinicalDocumentType>, required: true },
    },
    data: () => ({
        DrawerType,
        pendingMutation: false,
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
        blob: undefined as Blob | undefined,
        hash: null as Maybe<string> | undefined,
        ClinicalDocumentType,
    }),
    computed: {
        enableSend(): boolean {
            return !!this.hash;
        },
    },
    created() {
        this.getPdfDocument();
    },
    methods: {
        async sendDocument() {
            this.pendingMutation = true;
            try {
                await this.$apollo.mutate<Mutation>({
                    mutation: sendClinicalDocument,
                    variables: {
                        input: {
                            visitId: +this.visitId,
                            clinicalDocumentId: +this.clinicalDocumentId,
                            faxNumberId: +this.faxNumber.id,
                            hash: this.hash,
                        },
                    },
                });
                this.visitDrawerStore.closeDrawer();
                this.$toast.success(`Your ${ClinicalDocumentTypeDisplay[this.documentType]} has been sent!`);
                if (this.documentType === ClinicalDocumentType.CLINICAL_SUMMARY) {
                    this.visitStore.clinicalSummary = undefined;
                } else {
                    this.visitStore.appealLetterSummary = undefined;
                    this.visitStore.appealLetterId = this.clinicalDocumentId;
                    this.visitStore.appealedDenialId = this.visitStore.selectedDenialId;
                    this.visitStore.selectedDenialId = undefined;
                }
            } catch (e) {
                this.$toast.error(`Could not send ${ClinicalDocumentTypeDisplay[this.documentType]}, please try again`, { timeout: -1 });
            } finally {
                this.pendingMutation = false;
            }
        },
        async getPdfDocument() {
            try {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraftWithPDF,
                    variables: {
                        filter: {
                            visitId: +this.visitId,
                            type: this.documentType,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                const pdfBytes = response.data.clinicalDocumentDraft.pdf.data;
                this.blob = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' });
                this.hash = response.data.clinicalDocumentDraft.hash;
            } catch (e) {
                this.$toast.error(`Could not fetch ${ClinicalDocumentTypeDisplay[this.documentType]} data, please refresh your browser`, {
                    timeout: -1,
                });
            }
        },
        async deleteDocument() {
            this.visitStore.deleteDocument(this.documentType);
            this.visitDrawerStore.closeDrawer();
            this.$toast.success(`Your ${ClinicalDocumentTypeDisplay[this.documentType]} has been deleted!`);
        },
        async saveClinicalSummary(readyToSend: boolean) {
            await this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.visitId,
                        clinicalDocumentId: +this.clinicalDocumentId,
                        isReadyToSend: readyToSend,
                    },
                },
            });
            this.$toast.success('Clinical Summary Saved', {
                classes: 'toast-width-auto',
            });
        },
    },
});
